.ct-fw-bold{
    font-weight: 600;
}
.ct-min-card-closed-height{
    /* min-height: 85vh; */
}
.ct-quick-tips-min-width{
    min-width: 33%;
}
.quick_tab_space{
    padding: 50px 0;
    cursor: pointer;
}
.setting-fixed-icon{
    color: #FFFFFF;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
    font-size: 2em;
    font-weight: 900;
    text-rendering: auto;
    line-height: 1;
}
.footer{
    padding: 0 !important;
}
.saleHeading{
    font-size: 16px;
    font-weight: 600;
    border-bottom: 3px solid black;
}
.ck .ck-content{
    height: 300px !important;
}